import {
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import { pdfjs } from 'react-pdf';
import { useQuery } from '@tanstack/react-query';

import {
  algoliaTokenQuery,
  axiosHelper,
  sasTokenQuery,
  userInfoQuery,
  companyDataQuery,
  getCompanyModulesQuery
} from '@setvi/shared/services';
import { Analytics, AxiosMethods, UsersApi } from '@setvi/shared/enums';
import {
  User,
  CompanyData,
  CompanyModule
} from '@setvi/shared/services/react-query/query/user/types';
import { PageLoader } from 'Components/Shared/UI/Loaders/PageLoader';
import { useIsInIframe } from 'Hooks/useIsInIframe';
import { ROUTES } from 'enumsV2';
import { useReactQuery } from 'Hooks/React-Query';
import { useSearchParams } from 'react-router-dom';
import { AppContext } from './AppContext';

interface AppProviderProps {
  children: ReactNode;
}

let fetchMetadataCalled = false;

// Configure the worker source URL
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

moment.updateLocale('en', {
  longDateFormat: {
    LT: 'h:mm A',
    LTS: 'h:mm:ss A',
    L: 'MM/DD/YYYY',
    LL: 'MMM D, YYYY • HH:mmA',
    LLL: 'MM/DD/YYYY hh:mm A',
    LLLL: 'MM/DD/YYYY • h:mm a'
  }
});

// FIXME: This is a temporary solution for the iframe implementation and needs to be refactored
export const AppProvider = ({ children }: AppProviderProps) => {
  const { queryClient } = useReactQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const isIframe = useIsInIframe();
  const { logout } = useAuth0();
  const [token, setToken] = useState<string | null>(localStorage.token || null);
  const [layout, setLayout] = useState<boolean>(
    !(sessionStorage.getItem('layout') === 'false')
  );
  const [algoliaKey, setAlgoliaKey] = useState<string | null>(
    localStorage.algoliaKey || null
  );
  const [sasToken, setSasToken] = useState<string | null>(
    localStorage.sasToken || null
  );
  const [user, setUser] = useState<User | null>(
    JSON.parse(localStorage.user || null) || null
  );
  const [companyData, setCompanyData] = useState<CompanyData | null>(
    JSON.parse(localStorage.companyData || null) || null
  );
  const [companyModules, setCompanyModules] = useState<CompanyModule[]>(null);
  const [nylasToken, setNylasToken] = useState<string | null>(
    localStorage.nylasToken || null
  );
  const [initialLoader, setInitialLoader] = useState(true);
  const showLoaderInfo = searchParams.get('showLoaderInfo') === 'true';

  /* ******************************* THIS IS LOGIC FOR SAS TOKEN ******************************* */
  const sasQueryEnabled = useMemo(() => {
    if (!token) return false;

    const sasTokenExpires = localStorage.getItem('sasTokenExpires');
    if (!sasToken || !sasTokenExpires) return true;
    return parseInt(sasTokenExpires, 10) <= Date.now();
  }, [token, sasToken]);

  const { data: sasData, isInitialLoading: sasTokenLoading } = useQuery({
    ...sasTokenQuery(),
    enabled: sasQueryEnabled,
    select: res => res?.Data?.[0]
  });

  useEffect(() => {
    if (sasData) {
      const expiryDate = parseInt(sasData?.ExpiryDate.replace(/\D/g, ''), 10);
      if (expiryDate < Date.now()) return;

      setSasToken(sasData.Token);
      localStorage.setItem('sasToken', sasData.Token);
      localStorage.setItem('sasTokenExpires', expiryDate?.toString());
    }
  }, [sasData]);
  /* ********************************************************************************************** */

  const fetchMetadata = useCallback(
    async (_token: string) => {
      if (fetchMetadataCalled) return;

      fetchMetadataCalled = true;

      try {
        setInitialLoader(true);
        const responses = await Promise.allSettled([
          queryClient.fetchQuery(userInfoQuery()),
          queryClient.fetchQuery(algoliaTokenQuery()),
          queryClient.fetchQuery(companyDataQuery(_token)),
          queryClient.fetchQuery(getCompanyModulesQuery(_token))
        ]);

        responses.forEach(response => {
          if (response.status === 'rejected')
            throw new Error('Something went wrong, please try again later.');
        });

        /* @ts-ignore */
        setUser(responses[0]?.value?.Data);
        /* @ts-ignore */
        setAlgoliaKey(responses[1]?.value?.SearchApiKey);
        // @ts-ignore
        const companyDataResponse = responses[2]?.value?.Data[0] as CompanyData;
        const parsedCompanySettings = Object.fromEntries(
          companyDataResponse.CompanySettingsList.map(settings => [
            settings.CompanySettingsTypeID,
            settings
          ])
        );

        setCompanyData({
          ...companyDataResponse,
          Settings: parsedCompanySettings
        });
        // @ts-ignore
        setNylasToken(responses[0]?.value?.Data.EmailSyncAccessToken);
        // @ts-ignore
        setCompanyModules(responses[3]?.value?.Data);

        localStorage.setItem('metadataFetchedAt', String(Date.now()));
        setInitialLoader(false);
      } catch (error) {
        localStorage.clear();
        // FIXME: This is a temporary solution for the iframe implementation
        isIframe
          ? // eslint-disable-next-line no-alert
            alert(error)
          : logout({
              logoutParams: { returnTo: window.location.origin + ROUTES.LOGIN }
            });
      }

      fetchMetadataCalled = false;
    },
    [isIframe, logout, queryClient]
  );

  useLayoutEffect(() => {
    const tokenParams = searchParams.get('accessToken');
    const layoutParams = searchParams.get('layout');

    if (layoutParams) {
      const layoutVal = layoutParams !== 'false';
      sessionStorage.setItem('layout', String(layoutParams));
      setLayout(isIframe ? false : layoutVal);
      searchParams.delete('layout');
      setSearchParams(searchParams);
    }

    if (tokenParams) {
      localStorage.clear();
      setToken(tokenParams);
      searchParams.delete('accessToken');
      setSearchParams(searchParams);
    }
  }, [isIframe, searchParams, setSearchParams]);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
      fetchMetadata(token);
    }

    if (!token) setTimeout(() => setInitialLoader(false), 2000);
  }, [token, fetchMetadata]);

  useEffect(() => {
    if (algoliaKey) localStorage.setItem('algoliaKey', algoliaKey);
  }, [algoliaKey]);

  useEffect(() => {
    if (typeof nylasToken === 'string')
      localStorage.setItem('nylasToken', nylasToken);
    else localStorage.removeItem('nylasToken');
  }, [nylasToken]);

  useEffect(() => {
    const interval = setInterval(() => {
      const { metadataFetchedAt } = localStorage;
      const hoursPassed = moment().diff(
        moment(Number(metadataFetchedAt)),
        'hours'
      );

      // Refech tokens after 24 hours
      if (hoursPassed >= 23) fetchMetadata(token);
    }, 5000);

    return () => clearInterval(interval);
  }, [fetchMetadata, token]);

  useEffect(() => {
    if (token && user) {
      // Send analytics every time application is loaded
      axiosHelper({
        endpoint: UsersApi.UserActivity,
        method: AxiosMethods.POST,
        body: {
          ResourceID: '',
          TypeID: Analytics.IdleToActive,
          starttime: Date.now(),
          endtime: Date.now(),
          children: [],
          token,
          email: user.Email
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user]);

  if (initialLoader || sasTokenLoading)
    return <PageLoader showLoaderInfo={showLoaderInfo} />;

  return (
    <AppContext.Provider
      value={{
        token,
        algoliaKey,
        user,
        companyData,
        setCompanyData,
        companyModules,
        setCompanyModules,
        sasToken,
        layout,
        nylasToken,
        setNylasToken,
        setToken,
        setAlgoliaKey,
        setUser,
        setSasToken,
        setLayout
      }}>
      <LDProvider
        deferInitialization={!!user}
        clientSideID={process.env.LAUNCH_DARKLY_ID}
        context={{
          kind: 'user',
          key: String(user?.ID),
          name: user?.FullName,
          email: user?.Email,
          custom: {
            CompanyID: user?.CompanyID,
            CompanyName: user?.CompanyName,
            ExternalUserDomainId: user?.ExternalUserDomainId
          }
        }}>
        {children}
      </LDProvider>
    </AppContext.Provider>
  );
};
