// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

html,
body,
#root {
  height: 100%;
  overflow: hidden;
  background-color: #ffffff;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: Roboto, Helvetica, Arial, sans-serif, serif, Times, Monospace,
    Verdana, 'Trebuchet MS', Georgia;
  margin: 0;
}

body .rs-picker-daterange-menu {
  z-index: 10000;
}

.MuiSkeleton-root {
  background-color: #F9F9FD !important
}
 
.rs-picker-popup{
  z-index: 9999 !important;
}
 
@keyframes shake {
  0% { transform: translateY(0); }
  25% { transform: translateY(-2px); }
  50% { transform: translateY(2px); }
  75% { transform: translateY(-2px); }
  100% { transform: translateY(0); }
}`, "",{"version":3,"sources":["webpack://./App.css"],"names":[],"mappings":"AAEA;EACE,WAAW;AACb;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,6BAA6B;EAC7B,4BAA4B;AAC9B;;AAEA;;;EAGE,YAAY;EACZ,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,mCAAmC;EACnC,kCAAkC;EAClC,kCAAkC;EAClC;oCACkC;EAClC,SAAS;AACX;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;AACF;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,KAAK,wBAAwB,EAAE;EAC/B,MAAM,2BAA2B,EAAE;EACnC,MAAM,0BAA0B,EAAE;EAClC,MAAM,2BAA2B,EAAE;EACnC,OAAO,wBAAwB,EAAE;AACnC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');\n\n::-webkit-scrollbar {\n  width: 20px;\n}\n\n::-webkit-scrollbar-track {\n  background-color: transparent;\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: #d6dee1;\n  border-radius: 20px;\n  border: 6px solid transparent;\n  background-clip: content-box;\n}\n\nhtml,\nbody,\n#root {\n  height: 100%;\n  overflow: hidden;\n  background-color: #ffffff;\n}\n\nbody {\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  text-rendering: optimizeLegibility;\n  font-family: Roboto, Helvetica, Arial, sans-serif, serif, Times, Monospace,\n    Verdana, 'Trebuchet MS', Georgia;\n  margin: 0;\n}\n\nbody .rs-picker-daterange-menu {\n  z-index: 10000;\n}\n\n.MuiSkeleton-root {\n  background-color: #F9F9FD !important\n}\n \n.rs-picker-popup{\n  z-index: 9999 !important;\n}\n \n@keyframes shake {\n  0% { transform: translateY(0); }\n  25% { transform: translateY(-2px); }\n  50% { transform: translateY(2px); }\n  75% { transform: translateY(-2px); }\n  100% { transform: translateY(0); }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
